import React, { useState, useEffect } from "react"

import { getRates } from "../../services/extractReportsData"
import PaymentModal from "../Payments/PaymentModal"

export default function ({ reportItem }) {
  const [showPayPalButton, setShowPaypalButton] = useState(false)
  useEffect(() => {
    if (reportItem) {
      if (!showPayPalButton) setShowPaypalButton(true)
    }
  }, [reportItem])
  function showPaypal(reportItem) {
    const rate = document.getElementById("exampleFormControlSelect1")
    if (rate) {
      const option = rate.value
      let value = 0
      switch (option) {
        case "1":
          value = reportItem.single_user_price
          break
        case "2":
          value = reportItem.multiple_user_price
          break
        case "3":
          value = reportItem.one_cntry
          break
        case "4":
          value = reportItem.add_cntry
          break
        case "5":
          value = reportItem.direct_insights
          break
        case "6":
          value = reportItem.single_user_price
          break
        case "7":
          value = reportItem.multiple_user_price
          break
        case "8":
          value = reportItem.single
          break
        case "9":
          value = reportItem.multi
          break
        case "10":
          value = reportItem.database
          break
        default:
          value = 0
      }
      // return <PaypalButton total={value} currency={"USD"} />
      return (
        <PaymentModal
          buttonLabel={"Buy Now"}
          total={value}
          reportItem={reportItem}
          currency={"USD"}
        />
      )
    }
  }

  return (
    <>
      <select
        className={`form-control col-md-6 rounded-0 mt-2 mb-2 mr-3`}
        id="exampleFormControlSelect1"
        style={{ display: "inline" }}
      >
        {getRates(reportItem)}
      </select>
      {showPayPalButton && showPaypal(reportItem)}
    </>
  )
}
