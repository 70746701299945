import React from "react"
import Report from "../components/Report/Report"
import Layout from "../components/Base/Layout"

export default function({ pageContext }) {
  return (
    <Layout>
      <Report slug={pageContext.slug} />
    </Layout>
  )
}
